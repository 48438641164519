<template>
  <v-container fluid>
    <Breadcrumbs />

    <v-container
      v-if="!firstTimeFields && fieldList.length <= 0"
      fluid
      class="whiteBackGround"
    >
      <first-page
        :img-src="imgSrc"
        :dialog-name.sync="firstTimeFields"
        :points="points"
        :headline="headline"
      ></first-page>
    </v-container>

    <v-row class="pa-0 ma-0" v-if="firstTimeFields">
      <v-flex xs12 sm12 md12>
        <v-card flat>
          <v-card-text>
            <v-row>
              <!-- for input-->
              <v-col cols="12" sm="4">
                <v-card class="fill-height secondarygrad rounded">
                  <v-card-title class="ma-0 py-2 px-4">
                    <h5
                      class="secondary--text text--lighten-2 font-weight-medium"
                    >
                      Add New
                    </h5>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          style="opacity: 0.7"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          info_outline
                        </v-icon>
                      </template>
                      <span>Create your own fields for contact details</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      :value="field.name.toUpperCase()"
                      autofocus
                      label="Name Of Field"
                      :rules="rulesFieldname"
                      required
                      @input="field.name = $event.toUpperCase()"
                    >
                    </v-text-field>
                    <p>Field Type</p>
                    <v-radio-group v-model="field.type">
                      <v-radio
                        v-for="option in inputOptions"
                        :key="option.value"
                        :label="option.text"
                        :value="option.value"
                        color="primary"
                        @change="checkMcqType(option)"
                      >
                      </v-radio>
                    </v-radio-group>
                    <v-combobox
                      v-if="showMcqField"
                      ref="mcqBox"
                      v-model="mcqOptions"
                      class="text-uppercase"
                      label="Dropdown Options"
                      :delimiters="[',', ' ']"
                      multiple
                      chips
                    >
                    </v-combobox>
                    <v-combobox
                      v-if="showcheckboxField"
                      ref="checkboxBox"
                      v-model="checkboxOptions"
                      class="text-uppercase"
                      label="Checkbox Options"
                      :delimiters="[',', ' ']"
                      multiple
                      chips
                    >
                    </v-combobox>
                    <v-textarea
                      v-model="field.placeholder"
                      label="Placeholder"
                      placeholder="(optional)"
                    >
                    </v-textarea>
                    <v-btn
                      fill
                      block
                      color="primarygrad"
                      dark
                      class="rounded"
                      @click="add"
                    >
                      <v-icon :size="23" left>add_circle</v-icon> Add
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="8" style="margin:0px !important">
                <v-card flat class="fill-height rounded">
                  <v-card-title class="ma-0 py-2 px-4 px-sm-1">
                    <h5
                      class="secondary--text text--lighten-2 font-weight-medium"
                    >
                      Fields
                    </h5>
                  </v-card-title>
                  <v-card-text>
                    <v-row v-if="preloader" style="margin: 0px">
                      <v-flex xs12 sm12 md12 style="padding: 5px">
                        <v-card elevation="0">
                          <v-card-text style="text-align: center !important">
                            <v-progress-circular
                              :width="3"
                              color="green"
                              indeterminate
                            ></v-progress-circular>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </v-row>

                    <v-row
                      v-else-if="fieldList.length > 0"
                      style="margin: 0px; padding:0px"
                    >
                      <v-flex
                        v-for="field in fieldList"
                        :key="field._id"
                        xs12
                        sm6
                        md6
                        class="pa-1"
                      >
                        <v-card class="rounded fill-height">
                          <v-card-text style="margin:0px;">
                            <v-row style="margin: 0px">
                              <v-flex
                                xs10
                                sm10
                                md10
                                style="
                                  white-space: nowrap;
                                  width: 100px;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                <h3 class="px-2 font-weight-medium">
                                  {{ field.name }}
                                </h3>
                                <h5
                                  style="
                                    font-weight: 400;
                                    opacity: 0.6px;
                                    font-size: 13px;
                                    margin: 0px 0px 0px 10px;
                                  "
                                >
                                  {{
                                    field.type == "mcq"
                                      ? "dropdown"
                                      : field.type
                                  }}
                                </h5>
                              </v-flex>
                              <v-flex
                                xs2
                                sm2
                                md2
                                style="
                                  padding: 0px;
                                  margin-top: -7px;
                                  text-align: right !important;
                                "
                              >
                                <v-menu
                                  origin="center center"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn small text fab v-on="on">
                                      <v-icon size="20">more_vert</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <!-- <v-list-item @click="fieldEdit(field)">
                                      <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item> -->
                                    <v-list-item @click="del(field)">
                                      <v-list-item-title
                                        >Delete</v-list-item-title
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-flex>

                              <v-flex
                                xs12
                                sm12
                                md12
                                style="text-align: left; margin-top: 5px"
                              >
                                <p
                                  style="
                                    opacity: 0.8;
                                    font-size: 12px;
                                    margin: 0px 0px 0px 10px;
                                  "
                                >
                                  {{ field.placeholder }}
                                </p>
                              </v-flex>

                              <v-flex v-if="field.mcqOptions">
                                <v-slide-group show-arrows>
                                  <v-slide-item
                                    v-for="(option, key) in field.mcqOptions"
                                    :key="key"
                                  >
                                    <v-chip
                                      small
                                      label
                                      dark
                                      color="rounded"
                                      class="mx-1"
                                      >{{ option }}</v-chip
                                    >
                                  </v-slide-item>
                                </v-slide-group>
                              </v-flex>

                              <v-flex v-if="field.checkboxOptions">
                                <v-slide-group show-arrows>
                                  <v-slide-item
                                    v-for="(option,
                                    key) in field.checkboxOptions"
                                    :key="key"
                                  >
                                    <v-chip
                                      small
                                      label
                                      dark
                                      color="rounded"
                                      class="mx-1"
                                      >{{ option }}</v-chip
                                    >
                                  </v-slide-item>
                                </v-slide-group>
                              </v-flex>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-row>

    <v-dialog v-model="dialogfieldEdit" width="450">
      <v-card>
        <v-card-title class="primary white--text"> Edit </v-card-title>

        <v-card-text style="padding-top: 25px; padding-bottom: 25px">
          <v-flex xs12 sm12 md12>
            <v-text-field
              v-model="fieldEditedItem.name"
              label="Name"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-textarea
              v-model="fieldEditedItem.placeholder"
              label="Placeholder"
              placeholder="(optional)"
            ></v-textarea>
          </v-flex>

          <!-- <v-flex xs12 sm12 md12 style="text-align: center!important;">
            <v-select
              :items="inputOptions"
              placeholder="Field Type"
              item-text="text"
              item-value="value"
              :v-model="fieldEditedItem.type"
              required
            >
            </v-select>
          </v-flex> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="dialogfieldEdit = false"
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="updatefieldName"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const _ = require("lodash");
import fb from "../js/firebase";
import validator from "../js/validator.js";
import { mapGetters } from "vuex";
import Breadcrumbs from "../components/breadcrumbs.vue";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    Breadcrumbs,
    firstPage,
  },
  data() {
    return {
      firstTimeFields: false,
      headline: "Custom Fields for Deep Dives into Your Data",
      points: [
        "Boost Telecaller Efficiency: Equip your team with custom data fields pre-populated with relevant information, minimizing research time and maximizing call efficiency.",
        "Gather Comprehensive Insights: Design custom fields to capture specific customer details beyond basic contact information.",
        "Flexible Data Collection: Choose from a variety of field types, including dropdowns for easy selection, checkboxes for multi-choice answers, and date pickers for accurate information gathering.",
      ],
      dialogfieldEdit: false,
      fieldEditedItem: {
        name: "",
        placeholder: "",
        type: "text",
        required: false,
      },
      forbiddenFields: [
        "phone",
        "secondPhone",
        "name",
        "company",
        "remarks",
        "email",
        "extra",
        "address",
        "note",
      ],
      //TODO support boolean, choice, isPhoneNumber
      inputOptions: [
        { text: "Text", value: "text" },
        { text: "Number", value: "number" },
        { text: "Date", value: "date" },
        { text: "Dropdown", value: "mcq" },
        { text: "Checkbox", value: "checkbox" },
      ],
      preloader: false,
      field: {
        name: "",
        placeholder: "",
        type: "text",
        required: false,
      },
      rulesFieldname: [
        (v) =>
          /^\w+$/.test(v) ||
          "Maximum Fifteen characters & Must Not Contain Special Characters Or Space",
      ],
      mcqOptions: [],
      checkboxOptions: [],
      showMcqField: false,
      showcheckboxField: false,
    };
  },
  computed: {
    ...mapGetters(["fieldList"]),
    imgSrc() {
      return require("../assets/gotask.svg");
    },
  },
  watch: {
    fieldList() {
      this.preloader = false;
    },
  },
  mounted() {
    console.log("fieldList ..........", this.fieldList);

    if (this.fieldList.length > 0) {
      this.firstTimeFields = true;
    } else {
      this.firstTimeFields = false;
    }
  },
  created() {
    // this.$store.dispatch("getFields", {
    //   companyId: this.$store.getters.user.companyId,
    // });
    this.setForbiddenFields();
  },

  methods: {
    checkMcqType(option) {
      this.showMcqField = option.value == "mcq" ? true : false;
      this.showcheckboxField = option.value == "checkbox" ? true : false;
    },
    setForbiddenFields() {
      const self = this;
      self.fieldList.forEach((field) => {
        self.forbiddenFields.push(field.name.toLowerCase());
      });
    },
    fieldEdit(item) {
      this.fieldEditedItem = item;
      this.dialogfieldEdit = true;
    },
    updatefieldName() {
      const self = this;

      self.$http
        .patch(
          `${this.$store.state.ENDPOINT}/fields/${self.fieldEditedItem.id}`,
          self.fieldEditedItem
        )
        .then(
          (response) => {
            self.dialogfieldEdit = false;
            self.$store.dispatch("getFields", {
              companyId: this.$store.getters.user.companyId,
            });
          },
          (error) => {
            self.$swal({
              type: "warning",
              text: "Error occured. Please try again.",
            });
          }
        );
    },

    add() {
      // try {
      //   this.$refs.checkboxBox.$el.click();
      // } catch (e) {
      //   console.log(e);
      // }

      if (this.field.value == "") {
        console.log("** empty title **");
        return;
      }

      this.preloader = true;
      this.field.companyId = this.$store.getters.user.companyId;
      var checkFieldExists = this.forbiddenFields.includes(
        this.field.name.toLowerCase()
      );

      if (checkFieldExists) {
        this.preloader = false;
        return this.$swal({
          type: "warning",
          text: "Can't Create System define Or Already defined Fields",
        });
      }

      if (!/^\w+$/.test(this.field.name) || this.field.name.length > 15) {
        this.preloader = false;
        return this.$swal({
          type: "warning",
          text:
            "Maximum Fifteen characters & Must Not Contain Special Characters Or Space",
        });
      }

      if (this.field.type == "mcq" && this.mcqOptions.length < 1) {
        this.preloader = false;
        return this.$swal({
          type: "warning",
          text: "Add a dropdown option", //"Maximum Fifty Mcq options & Must Not Contain Space",
        });
      }

      if (this.field.type == "mcq" && this.mcqOptions.length > 50) {
        this.preloader = false;
        return this.$swal({
          type: "warning",
          text: "Maximum Fifty options", //"Maximum Fifty Mcq options & Must Not Contain Space",
        });
      }
      if (
        this.field.type == "checkbox" &&
        (this.checkboxOptions.length > 50 || this.checkboxOptions.length == 0)
      ) {
        this.preloader = false;
        return this.$swal({
          type: "warning",
          text: "Maximum Fifty options", //"Maximum Fifty Check Box options & Must Not Contain Space",
        });
      }
      if (this.field.type == "mcq") {
        this.field.mcqOptions = _.map(this.mcqOptions, (o) => {
          return o.toUpperCase();
        });
      }
      if (this.field.type == "checkbox") {
        this.field.checkboxOptions = _.map(this.checkboxOptions, (o) => {
          return o.toUpperCase();
        });
      }

      this.$http
        .post(`${this.$store.state.ENDPOINT}/fields`, this.field)
        .then((response) => {
          this.field = {};
          this.field.name = "";
          this.field.type = "text";
          this.field.placeholder = "";
          this.mcqOptions = "";
          this.checkboxOptions = "";
          this.field.required = false;
          this.$store.dispatch("getFields", {
            companyId: this.$store.getters.user.companyId,
          });
          fb.log("create_field");

          // if (this.fieldList.length <= 0) {
          //   this.firstTimeFields = false;
          // }

          return this.$swal({
            type: "success",
            text:
              "Please inform all app users to logout and login, to see the new changes.",
          });
        })
        .catch((e) => {
          this.preloader = false;
          if (e.body.error) {
            this.$swal({
              type: "error",
              text: e.body.error.message,
            });
          } else {
            this.$swal({
              type: "error",
              text: "Error while saving this field",
            });
          }
        });
    },
    del(item) {
      this.preloader = true;

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Contacts with this field will not show the data any more",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(`${this.$store.state.ENDPOINT}/fields/${item.id}`)
              .then((response) => {
                this.preloader = false;
                this.$store.dispatch("getFields", {
                  companyId: this.$store.getters.user.companyId,
                });
                fb.log("delete_field");

                return this.$swal({
                  type: "success",
                  text:
                    "Please inform all app users to logout and login, to see the new changes.",
                });
              })
              .catch((e) => {
                this.preloader = false;
                console.log(e);
              });
          }
          this.preloader = false;
        });
    },
  },
};
</script>

<style scoped>
.widthCustom {
  max-width: 600px !important;
}
</style>
